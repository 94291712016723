import * as React from 'react';
import {PureComponent} from 'react';
import "../../styles/Home.css";

export default class ControlPanel extends PureComponent {
  render() {
    return (
      <div className="control-panel">

         <div>Don't see your University or Program Listed? 
           <br/>
           <a href="https://csfored-stage.web.app/contact">Contact Us</a> to add to your Institution</div>
      </div>
    );
  }
}
