import React, { Component } from "react";
import book from "../../images/workshop-2017/book.svg";
import circuit from "../../images/workshop-2017/circuit.svg";
import notebook from "../../images/workshop-2017/notebook.svg";
import "../../styles/Workshop2017.css";
export class FindingAHomeForCs extends Component {
  render() {
    return (
      <div>
        <div className="workshop-2017-hero">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="workshop-2017-hero-container ">
                  <h2 className="workshop-2017-hero-text">
                    Finding a Home for Computing Education in Schools of
                    Education Strategy Workshop
                  </h2>
                  <hr className="hr-2017-workshop"></hr>
                  <p className="workshop-2017-hero-subText">
                    08 - 09 April 2017
                  </p>
                  <p className="workshop-2017-hero-subText">
                    Microsoft Reactor Hub @ Grand Central Tech
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {/* I N T R O   S E C T I O N */}
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="workshop-2017-overview-box">
                <hr className="hr-overview-2017-workshop mx-auto"></hr>
                <h1 className="workshop-2017-intro-heading">Overview</h1>
                <div className="workshop-intro-content">
                  <p>
                    In order to address critical questions related to the
                    integration of computing education into schools of
                    education, together computer science education researchers,
                    leaders from colleges of education, teacher educators, and
                    computer scientists participated in the Finding a Home for
                    Computing Education in Schools of Education Strategy
                    Workshop to explore options and share current practices.
                  </p>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div className="video-container">
          <div className="video-iframe-container">
            <img className="ratio" src="http://placehold.it/16x9" alt='blank rectangle to set aspect ratio of video'/>
                <iframe
                    src="https://player.vimeo.com/video/241555301"
                    frameBorder="0"
                    webkitallowfullscreen='true'
                    mozallowfullscreen='true'
                    allowFullScreen={true}
                    title="csfored-overview"
                  ></iframe>
            </div>
        </div>

        {/* G O A L S  S E C T I O N  */}
        <div className="workshop-2017-goals-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="workshop-2017-goals-info ">
                  <h2>Goals</h2>
                  <p>
                    The April 2017 workshop addressed the issues surrounding the
                    expansion of computing education into teacher education
                    programs, specifically as it related to schools of education
                    with the following goals
                  </p>
                </div>
              </div>
            </div>
            <div className="row workshop-2017-goals-items">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <img
                  className="workshop-2017-goals-item-image"
                  src={book}
                  alt="CS textbook illustration"
                />
                {/* <h3 className="workshop-2017-goals-item-heading">Emphasize</h3> */}
                <p className="workshop-2017-goals-item-text ">
                  To emphasize the importance of computing education as a
                  domain-based education research discipline, like physics and
                  mathematics education.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 ">
              <img
                  className="workshop-2017-goals-item-image"
                  src={notebook}
                  alt="CS notes illustration"
                />
                {/* <h3 className="workshop-2017-goals-item-heading">
                  Model Quality & Equity
                </h3> */}
                <p className="workshop-2017-goals-item-text ">
                  To provide models to Colleges of Education for integrating
                  computing education, and especially, to deal with the unique
                  challenges of computing education, e.g., the need to
                  address issues of equity and under-representation.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 ">
             
                  <img
                  className="workshop-2017-goals-item-image"
                  src={circuit}
                  alt="circuit illustration"
                />
                {/* <h3 className="workshop-2017-goals-item-heading">
                  Collaborate
                </h3> */}
                <p className="workshop-2017-goals-item-text mx-auto">
                  To work alongside Colleges of Education to explore
                  credentialing issues with regard to computer science.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* STRATEGIES SECTION */}
        <div className="container">
          <div className="workshop-2017-strategies-section">
            <hr className="hr-strategies-2017-workshop "></hr>
            <h2>Strategies for innovation</h2>
            <div className="row">
              <div className="col-lg-6 col-md-12 ">
                <p>
                  We challenge US Colleges of Education to build on the new
                  knowledge and research in computer science education to
                  integrate this new discipline into their programs. What we
                  propose is a change to the American Education canon. Such
                  enormous change requires innovating in different ways, using
                  different models and strategies, before finding models that
                  work.
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <p>
                  This workshop was about developing those strategies. As a
                  start, we built on existing successful efforts in the largest
                  school districts—New York City and Los Angeles—in the US and
                  by bringing in a group of interdisciplinary faculty from
                  schools of education and computer science to workshops.
                </p>
              </div>
            </div>
          </div>
        </div>
            {/* <div className="row"> */}
              
                <div className="image-break" alt="Three members of central union school district talk at a CSforALL symposium">
                <div className="image-break-container"></div>
              </div>
            {/* </div> */}
          {/* </div> */}


        <div className="container">
          <div className="workshop-2017-framework-section">
            <hr className="hr-framework-2017-workshop "></hr>
            <h2>Developing Framework, Models, and Research Agenda </h2>
            <p>
              In this workshop, we aimed to describe a pathway for schools of
              education to integrate computing education into their teacher
              education programs. Schools of Education and pre-service teacher
              education programs already have existing curricular structures and
              mandates. Any approach to Computing Education needs to integrate
              within these existing mechanisms — we cannot take a tabula rasa
              stance. As a result of the proposed workshop, we anticipated
              defining the teacher education pipeline for making computing
              education part of every American student's experience
            </p>
          </div>

          {/* FIRST  */}
          <div className="row workshop-2017-framework-section-content">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="workshop-2017-framework-section-text-container">
                <h3>Framework</h3>
                <p>
                  We defined a framework to explain how computing education can
                  coherently be a part of education pre-service programs, e.g.,
                  what classes need to be added, what content needs to be added
                  to existing classes.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <img
                src='https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fteach-cs.jpg?alt=media&token=89dc916c-d1c2-45b9-b1d0-9e5956abb222'
                alt='Printout of a "we should teach CS because" rubric'
              />
            </div>
          </div>
          {/* SECOND  */}
          <div className="row workshop-2017-framework-section-content flex-column-reverse flex-lg-row">
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <img
                src='https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fsymp3.jpg?alt=media&token=08d2a124-c2b8-4f45-89c4-6d7407722447'
                alt="Two members of boston public schools, a man and a woman, discuss topics at a SCRIPT symposium"
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="workshop-2017-framework-section-text-container">
                <h3>Research Agenda</h3>
                <p>
                  The evidence-base for computing teacher development is too
                  shallow. We needed to know more to create our framework and
                  model. We defined a research agenda to address the unanswered
                  questions about how computing education fits into primary and
                  secondary school milieux.
                </p>
              </div>
            </div>
          </div>
          {/* THIRD  */}
          <div className="row workshop-2017-framework-section-content">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="workshop-2017-framework-section-text-container">
                <h3>Models</h3>
                <p>
                  We provided representative models for integrating computing
                  education content into elementary and secondary school
                  programs so that it becomes obvious what content teachers need
                  for teaching different student audiences.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <img
                src='https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fpost-its.jpg?alt=media&token=6336333b-36c8-4cf6-827a-5eb630117b22'
                alt="CSforALL exercise on values, printed sheet with post-its stuck to the page."
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FindingAHomeForCs;
