import React, { Component } from "react";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import focusAreas from "./FocusAreas";
import "../../styles/Report2018.css";
import chevronWhiteUp from "../../images/Chevron-white-up.svg";
import chevronWhiteDown from "../../images/Chevron-white-down.svg";
import Slide from "react-reveal/Slide";

export class FocusAreasAccordion extends Component {
  state = {
    activeKey: "0",
  };
  handleSelect = (activeKey) => {
    this.setState({ activeKey });
  };
  render() {
    return (
      <Accordion
        defaultActiveKey="0"
        activeKey={this.state.activeKey}
        onSelect={this.handleSelect}
        style={{ marginBottom: "10%" }}
      >
        <Slide bottom>
          <div>
            <Card className="report-2018-accordion-card">
              <Accordion.Toggle
                className="report-2018-accordion-head"
                as={Card.Header}
                eventKey="1"
              >
                {focusAreas.focusArea1.name}
                <span style={{ float: "right" }}>
                  {this.state.activeKey === "1" ? (
                    <img alt="" src={chevronWhiteUp}></img>
                  ) : (
                    <img alt="" src={chevronWhiteDown}></img>
                  )}
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="report-2018-card-body">
                  <p>{focusAreas.focusArea1.paragraph1}</p>
                  <p>{focusAreas.focusArea1.paragraph2}</p>
                  <p>{focusAreas.focusArea1.paragraph3}</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Slide>
        <Slide bottom>
          <div>
            <Card className="report-2018-accordion-card">
              <Accordion.Toggle
                className="report-2018-accordion-head"
                as={Card.Header}
                eventKey="2"
              >
                {focusAreas.focusArea2.name}
                <span style={{ float: "right" }}>
                  {this.state.activeKey === "2" ? (
                    <img alt="" src={chevronWhiteUp}></img>
                  ) : (
                    <img alt="" src={chevronWhiteDown}></img>
                  )}
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body className="report-2018-card-body">
                  <p>{focusAreas.focusArea2.paragraph1}</p>
                  <p>{focusAreas.focusArea2.paragraph2}</p>
                  <p>{focusAreas.focusArea2.paragraph3}</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Slide>
        <Slide bottom>
          <div>
            <Card className="report-2018-accordion-card">
              <Accordion.Toggle
                className="report-2018-accordion-head"
                as={Card.Header}
                eventKey="3"
              >
                {focusAreas.focusArea3.name}
                <span style={{ float: "right" }}>
                  {this.state.activeKey === "3" ? (
                    <img alt="" src={chevronWhiteUp}></img>
                  ) : (
                    <img alt="" src={chevronWhiteDown}></img>
                  )}
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body className="report-2018-card-body">
                  <p>{focusAreas.focusArea3.paragraph1}</p>
                  <p>{focusAreas.focusArea3.paragraph2}</p>
                  <p>{focusAreas.focusArea3.paragraph3}</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Slide>
        <Slide bottom>
          <div>
            <Card className="report-2018-accordion-card">
              <Accordion.Toggle
                as={Card.Header}
                className="report-2018-accordion-head"
                eventKey="4"
              >
                {focusAreas.focusArea4.name}
                <span style={{ float: "right" }}>
                  {this.state.activeKey === "4" ? (
                    <img alt="" src={chevronWhiteUp}></img>
                  ) : (
                    <img alt="" src={chevronWhiteDown}></img>
                  )}
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body className="report-2018-card-body">
                  <p>{focusAreas.focusArea4.paragraph1}</p>
                  <p>{focusAreas.focusArea4.paragraph2}</p>
                  <p>{focusAreas.focusArea4.paragraph3}</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Slide>
        <Slide bottom>
          <div>
            <Card className="report-2018-accordion-card">
              <Accordion.Toggle
                className="report-2018-accordion-head"
                as={Card.Header}
                eventKey="5"
              >
                {focusAreas.focusArea5.name}
                <span style={{ float: "right" }}>
                  {this.state.activeKey === "5" ? (
                    <img alt="" src={chevronWhiteUp}></img>
                  ) : (
                    <img alt="" src={chevronWhiteDown}></img>
                  )}
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body className="report-2018-card-body">
                  <p>{focusAreas.focusArea5.paragraph1}</p>
                  <p>{focusAreas.focusArea5.paragraph2}</p>
                  <p>{focusAreas.focusArea5.paragraph3}</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Slide>
        <Slide bottom>
          <div>
            <Card className="report-2018-accordion-card">
              <Accordion.Toggle
                className="report-2018-accordion-head"
                as={Card.Header}
                eventKey="6"
              >
                {focusAreas.focusArea6.name}
                <span style={{ float: "right" }}>
                  {this.state.activeKey === "6" ? (
                    <img alt="" src={chevronWhiteUp}></img>
                  ) : (
                    <img alt="" src={chevronWhiteDown}></img>
                  )}
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body className="report-2018-card-body">
                  <p>{focusAreas.focusArea6.paragraph1}</p>
                  <p>{focusAreas.focusArea6.paragraph2}</p>
                  <p>{focusAreas.focusArea6.paragraph3}</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Slide>
        <Slide bottom>
          <div>
            <Card className="report-2018-accordion-card">
              <Accordion.Toggle
                className="report-2018-accordion-head"
                as={Card.Header}
                eventKey="7"
              >
                {focusAreas.focusArea7.name}
                <span style={{ float: "right" }}>
                  {this.state.activeKey === "7" ? (
                    <img alt="" src={chevronWhiteUp}></img>
                  ) : (
                    <img alt="" src={chevronWhiteDown}></img>
                  )}
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body className="report-2018-card-body">
                  <p>{focusAreas.focusArea7.paragraph1}</p>
                  <p>{focusAreas.focusArea7.paragraph2}</p>
                  <p>{focusAreas.focusArea7.paragraph3}</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Slide>
        <Slide bottom>
          <div>
            <Card className="report-2018-accordion-card">
              <Accordion.Toggle
                className="report-2018-accordion-head"
                as={Card.Header}
                eventKey="8"
              >
                {focusAreas.focusArea8.name}
                <span style={{ float: "right" }}>
                  {this.state.activeKey === "8" ? (
                    <img alt="" src={chevronWhiteUp}></img>
                  ) : (
                    <img alt="" src={chevronWhiteDown}></img>
                  )}
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="8">
                <Card.Body className="report-2018-card-body">
                  <p>{focusAreas.focusArea8.paragraph1}</p>
                  <p>{focusAreas.focusArea8.paragraph2}</p>
                  <p>{focusAreas.focusArea8.paragraph3}</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Slide>
      </Accordion>
    );
  }
}

export default FocusAreasAccordion;
