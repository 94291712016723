import React, { Component } from "react";
import "../styles/States.css"; 
export class StatePdfs extends Component {

    render() {

        return(
          
              <div> 
                   <div> 
                        {/* states hero S E C T I O N */}
                        <div className="states-hero">
                        <div style={{ background: "none" }} className="jumbotron ">
                        
                        <div className="spacer"></div>
                            <h2 className="states-hero-text"> State Reports  </h2>
                        </div>
                    </div>
                </div>

                {/* state links */}
                <div className='states-quote-section raleway'>
                    <div className='states-links' onClick={() => window.location.replace("/states-pdfs/#a-c")}>A-C</div>
                    <div className='states-links' onClick={() => window.location.replace("/states-pdfs/#d-h")}>D-H</div>
                    <div className='states-links' onClick={() => window.location.replace("/states-pdfs/#i-l")}>I-L</div>
                    <div className='states-links' onClick={() => window.location.replace("/states-pdfs/#m")}>M</div>
                    <div className='states-links' onClick={() => window.location.replace("/states-pdfs/#n")}>N</div>
                    <div className='states-links' onClick={() => window.location.replace("/states-pdfs/#o-t")}>O-T</div>
                    <div className='states-links' onClick={() => window.location.replace("/states-pdfs/#u-z")}>U-Z</div>
                </div>

                <div className='spacer'></div>

                {/* states */}
            <div className='states-container raleway'>
                <div id='a-c'></div>
                <h2>Alabama</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FALABAMA.pdf?alt=media&token=fcb915bc-000e-495d-9b0b-1c8a5907fe07">State Report PDF</a>
                <h2>Alaska</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FALASKA.pdf?alt=media&token=0833124d-0658-4728-b761-024f4f1948b8">State Report PDF</a>
                <h2>Arizona</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FARIZONA.pdf?alt=media&token=fc0f8d3e-cb3c-4972-bff7-99f983d8b225">State Report PDF</a>
                <h2>Arkansas</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FARKANSAS.pdf?alt=media&token=42a3b30d-70b0-4374-a6f8-8b84d4b8740e">State Report PDF</a>
                <h2>California</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FCALIFORNIA.pdf?alt=media&token=d0ee0d1a-535c-477d-b57a-8f29e162e315">State Report PDF</a>
                <h2>Colorado</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FCOLORADO.pdf?alt=media&token=d5a3924e-471c-4e47-a317-dd522218ea3e">State Report PDF</a>
                <h2>Connecticut</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FCONNECTICUT.pdf?alt=media&token=da2c3018-a808-40a7-a37f-ce7a4daa1204">State Report PDF</a>

                <div id='d-h'></div>
                <h2>Delaware</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FDELAWARE.pdf?alt=media&token=bc5aaec1-85ba-4ca3-9dfd-6e6020f58fef">State Report PDF</a>
                <h2>District of Columbia</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FDISTRICT%20OF%20COLUMBIA.pdf?alt=media&token=e0496a66-6706-4f8f-a30a-d5e2dc0b00f8">State Report PDF</a>
                <h2>Florida</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FFLORIDA.pdf?alt=media&token=e9474328-9cdb-4140-8749-92a135e3f1a3">State Report PDF</a>
                <h2>Georgia</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FGEORGIA.pdf?alt=media&token=6a56dd8e-52bd-4dca-b740-01757023413d">State Report PDF</a>
                <h2>Hawaii</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FHAWAII.pdf?alt=media&token=db50fe66-57eb-4323-bcf3-bcbf4b33c7d3">State Report PDF</a>

                <div id='i-l'></div>
                <h2>Idaho</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FIDAHO.pdf?alt=media&token=c3c3a73f-3582-4dbe-87ab-177ab77723a5">State Report PDF</a>
                <h2>Illinois</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FILLINOIS.pdf?alt=media&token=7fbbd528-d7a7-4f63-a27b-3033731d962e">State Report PDF</a>
                <h2>Indiana</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FINDIANA.pdf?alt=media&token=0056fe54-07f6-47f5-9544-f45ac72897da">State Report PDF</a>
                <h2>Iowa</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FIOWA.pdf?alt=media&token=9fe629bc-db36-411b-b548-fb04e0c29705">State Report PDF</a>
                <h2>Kansas</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FKANSAS.pdf?alt=media&token=47b6f0f2-e1e9-461e-b94b-6343a540ab92">State Report PDF</a>
                <h2>Kentucky</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FKENTUCKY.pdf?alt=media&token=d9ce8301-1d16-4785-97c6-207c760d65f2">State Report PDF</a>
                <h2>Louisiana</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FLOUISIANA.pdf?alt=media&token=d128ea23-0fc7-4ce0-857d-877f04979e4a">State Report PDF</a>

                <div id='m'></div>
                <h2>Maine</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FMAINE.pdf?alt=media&token=34ddcfcd-216f-4b18-97b9-b6026d1898d5">State Report PDF</a>
                <h2>Maryland</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FMARYLAND.pdf?alt=media&token=c7686efa-191f-468a-9e42-3f7d23d33e5b">State Report PDF</a>
                <h2>Massachusetts</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FMASSACHUSETS.pdf?alt=media&token=72cdc1ba-6002-481a-8a10-3810c5ada6fa">State Report PDF</a>
                <h2>Michigan</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FMICHIGAN.pdf?alt=media&token=717d677f-1ac2-46fd-a84c-07e75afd28c7">State Report PDF</a>
                <h2>Minnesota</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FMINNESOTA.pdf?alt=media&token=7d166cb8-0a60-401c-bda1-57915a7cfc2e">State Report PDF</a>
                <h2>Mississippi</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FMISSISSIPPI.pdf?alt=media&token=1c1eaed2-d0d7-4d4c-9f0b-c1d3be66fa20">State Report PDF</a>
                <h2>Missouri</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FMISSOURI.pdf?alt=media&token=3ac9d7cd-209b-40b8-bd19-13fb74e70f60">State Report PDF</a>
                <h2>Montana</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FMONTANA.pdf?alt=media&token=5ff59c35-e120-4051-95d9-6bf4238a0911">State Report PDF</a>

                <div id='n'></div>
                <h2>Nebraska</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FNEBRASKA.pdf?alt=media&token=4787f8c0-fad7-45ee-a4e7-cf2d5d99ba61">State Report PDF</a>
                <h2>Nevada</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FNEVADA.pdf?alt=media&token=2348b886-a7f8-402d-a5bb-d8df73d11f26">State Report PDF</a>
                <h2>New Hampshire</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FNEW%20HAMPSHIRE.pdf?alt=media&token=30318457-c07f-433d-93ab-57a4da824eb2">State Report PDF</a>
                <h2>New Jersey</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FNEW%20JERSEY.pdf?alt=media&token=bfd4cbe0-a44b-4f6b-9ef2-6401349cf706">State Report PDF</a>
                <h2>New Mexico</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FNEW%20MEXICO.pdf?alt=media&token=9cd007fa-1bd5-4988-a307-27c2fca97a0d">State Report PDF</a>
                <h2>New York</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FNEW%20YORK.pdf?alt=media&token=1e5db51b-59ce-4f5e-900f-4246a4a3e03f">State Report PDF</a>
                <h2>North Carolina</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FNORTH%20CAROLINA.pdf?alt=media&token=406945f5-ecb6-4e4c-85b2-2503b733aec5">State Report PDF</a>
                <h2>North Dakota</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FNORTH%20DAKOTA.pdf?alt=media&token=4e654fe6-8dee-4ee3-b9da-2447b56f4d13">State Report PDF</a>

                <div id='o-t'></div>
                <h2>Ohio</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FOHIO.pdf?alt=media&token=f03a5436-3c4d-4f30-8c34-3cef24fc42c4">State Report PDF</a>
                <h2>Oklahoma</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FOKLAHOMA.pdf?alt=media&token=6b436f6c-6597-41a2-8b25-f3fb97f117ca">State Report PDF</a>
                <h2>Oregon</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FOREGON.pdf?alt=media&token=a1746006-cd5c-4706-9b6a-3e3ff6adc64b">State Report PDF</a>
                <h2>Pennsylvania</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FPENNSYLVANIA.pdf?alt=media&token=ff44974c-2856-4596-84bc-c7e43b4cff7d">State Report PDF</a>
                <h2>Rhode Island</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FRHODE%20ISLAND.pdf?alt=media&token=e94da7f1-f742-4b24-9da1-4574858f02eb">State Report PDF</a>
                <h2>South Carolina</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FSOUTH%20CAROLINA.pdf?alt=media&token=84b5f6fd-0735-436d-bc29-5ab4794981b7">State Report PDF</a>
                <h2>South Dakota</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FSOUTH%20DAKOTA.pdf?alt=media&token=8917f6cf-7f56-4141-aa0d-677086966361">State Report PDF</a>
                <h2>Tennessee</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FTENNESSEE.pdf?alt=media&token=d7b5ed08-d82e-4c54-86a4-7bfa22bfe699">State Report PDF</a>
                <h2>Texas</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FTEXAS.pdf?alt=media&token=71711ac0-4e68-42e4-a40b-99a1cd2c7aa3">State Report PDF</a>

                <div id='u-z'></div>
                <h2>Utah</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FUTAH.pdf?alt=media&token=dc6ee6af-ae56-4e78-83f7-4085a175d632">State Report PDF</a>
                <h2>Vermont</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FVERMONT.pdf?alt=media&token=94962a3b-e433-4da2-a243-aa3f33e19304">State Report PDF</a>
                <h2>Virginia</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FVIRGINIA.pdf?alt=media&token=dcac5df5-158b-464c-893b-65c3c2512abd">State Report PDF</a>
                <h2>Washington</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FWASHINGTON.pdf?alt=media&token=23a70473-894a-41cb-b350-197e12d3962e">State Report PDF</a>
                <h2>West Virginia</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FWEST%20VIRGINIA.pdf?alt=media&token=f8d40e3e-5845-4a42-9750-b7686a964092">State Report PDF</a>
                <h2>Wisconsin</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FWISCONSIN.pdf?alt=media&token=c08c599e-14d1-4f5b-96b4-083dee12eab9">State Report PDF</a>
                <h2>Wyoming</h2>
                <a className="map-btn-states-pdfs" target='_blank' rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Fstate-pdfs%2FWYOMING.pdf?alt=media&token=f453fc29-0593-4e96-aa7f-7bd3a93a6443">State Report PDF</a>
            </div>

            </div>
        );
    }
}
export default StatePdfs;