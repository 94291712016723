// firebase google analytics
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/analytics";

const { REACT_APP_API, 
        REACT_APP_AUTH, 
        REACT_APP_DATABASE, 
        REACT_APP_PROJECTID, 
        REACT_APP_SBUCKET, 
        REACT_APP_MESSAGEID, 
        REACT_APP_APPID, 
        REACT_APP_MEASURMENTID } = process.env;

    var firebaseConfig = {
        apiKey: REACT_APP_API,
        authDomain: REACT_APP_AUTH,
        databaseURL: REACT_APP_DATABASE,
        projectId: REACT_APP_PROJECTID,
        storageBucket: REACT_APP_SBUCKET,
        messagingSenderId: REACT_APP_MESSAGEID,
        appId: REACT_APP_APPID,
        measurementId: REACT_APP_MEASURMENTID
      };


      firebase.initializeApp(firebaseConfig);
      // const analytics = firebase.analytics();

export { firebase };
