import React, { Component } from "react";
import "../../styles/Report2016.css";
import { Button} from 'reactstrap';

// for PDF
const pdfFile = 'https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2FBuilding_a_Computer_Science_Teacher_Pipeline_for_New_York_City.pdf?alt=media&token=03935216-e95c-4ff8-ba68-91bb55ea9db7';

export class Report2016 extends Component {
  render() {
  return (
    <div>
      <div className='pdf-container'>
        {pdfFile && <iframe src={pdfFile}  className="pdf-frame" title='pdf'/>}

        </div>
        <div className="spacer"></div>
            <div className='center'>
              <h4>Download a copy of the 2016 Report</h4>
              <a className=" btn resourcesButton" role="button" href={pdfFile} download="CSforED 2016 Report.pdf" >
                <Button className='primary-button'>HERE</Button>
              </a>
            </div>
        <div className="spacer"></div>
    </div>
    );
  }
}
export default Report2016;