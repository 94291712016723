import React, { Component } from "react";
import {Row, Col} from 'react-bootstrap';
import '../styles/About.css'

import cert from "../images/About/cert.png";
import mag from "../images/About/mag.png";
import cap from "../images/About/grad-cap.png";
  
import robot from '../images/About/robots2.jpg';

// TIMELINE
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
// ICONS
import { FaAtom } from 'react-icons/fa';
import { FaLaptopCode } from 'react-icons/fa';
export class About extends Component {
  render() {
 
    return(
      
          <div> 
          {/* intro hero S E C T I O N */}
           <div className="about-hero">
          <div style={{ background: "none" }} className="jumbotron ">
           
           <div className="spacer"></div>
            <h2 className="about-hero-text"> The Mission  </h2>
            <div className="spacer"></div>
            <p className='about-subtitle'>
            Teacher preparation programs, and specifically schools of education, are the best institutions to prepare tomorrow's teachers for the classrooms of today. High quality computer science education depends upon the inclusion of CS into high quality teacher preparation.            </p>
         </div>
         </div>
      

        {/* Q U O T E  S E C T I O N */}
          <div className="about-quote-section">
            <div className="about-quote-vert">
              <p className='about-quote-title'>
              The Vision
              </p>
               <h3 className="about-quote">
               CSforED is the central resource for data, recommendations, guidance, and case studies of computer science education in teacher preparation programs.
              </h3>
              </div>
          </div>

          {/* I N T R O   S E C T I O N */}
                <div className="about-story-box">
                <h1 className="about-story-heading">The CSforEd Story</h1>
                <Row>
                <Col lg={{ size: 6, order: 2 }} xl={6}>
                <img src={robot} className='story-img' alt='child holdind a robot'></img>
                </Col>

                  <Col lg={{ size: 6, order: 1 }} xl={6}>
                <div className="about-story-content">
                  <p>
                  Computing education is bursting onto the K-12 education scene in the United States. On January 30, 2016, President Obama announced that computer science for all initiative. For example, New York City has mandated every school to to provide computing education to all students by 2025; similarly, Chicago is making education in "coding" a requirement. The recently passed federal education legislation notably includes CS in the list of “well-rounded” and critically enriching subjects. States are rapidly developing certification credentials for computing teachers.  
                  </p>
                  <p>
                  With the expansion of computing education in mainstream K-12 schools, the current training mechanisms for teachers quickly will fall short of supporting a sustainable pipeline of teachers. Current methods of preparing teachers often rely on identifying in-service teachers and providing them professional development in a specific program or curricula. If CS is going to become a core subject in K-12, the community needs to engage with current methods of teacher preparation to produce not only CS teachers at scale, but also update the technological preparation of K-8 teachers to include computer science topics and computational thinking. 
                  </p> 
                </div>
                </Col>
                </Row>
                <div className='spacer'></div>
                  <p className='about-story-content'>
                   <b>Funding </b>for the Finding a Home for Computer Science Education in Schools of Education project was provided through grant number 1636881 by the National Science Foundation. Any opinions, findings, or conclusions or recommendations expressed in this material are those of the authors and do not necessarily reflect the views of the National Science Foundation.
                  </p>
              </div>
           
 

          {/* G O A L S  S E C T I O N  */}
          <div className="about-goals-section">
                  <div className="about-goals-info">
                  <h2 className='about-goals-bold'>Goals</h2>
                  <p className='about-goals-text'>
                  In order to address these challenges we propose to bring 
                  together CS education researchers, leaders from departments 
                  of education, teacher education researchers, and computer 
                  scientists in a workshop to help answer the above questions 
                  and more. The proposed workshops address the issues 
                  surrounding the expansion of Computing Education into 
                  teacher education programs, specifically as it related 
                  to schools of education with the following goals.
                  </p>
                </div>

                <div className="container">
             <div className="row about-goals-items">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <img
                  className="about-goals-item-image"
                  src={cap}
                  alt="graduation cap illustration"
                />
                {/* <h3 className="about-goals-item-heading">nostrud</h3> */}
                <p className="about-goals-item-text ">
                  To emphasize the importance of computing education as a
                  domain-based education research discipline, like physics and
                  mathematics education.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 ">
                <img
                  className="about-goals-item-image"
                  src={mag}
                  alt="magnifying glass illustration"
                />
                {/* <h3 className="about-goals-item-heading">
                  Lorem ipsum
                </h3> */}
                <p className="about-goals-item-text ">
                  To provide models to Colleges of Education for integrating
                  computing education, and especially, to deal with the unique
                  challenges of computingeducation, e.g., the need to
                  addressissues of equity and under-representation.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 ">
                <img
                  className="about-goals-item-image"
                  src={cert}
                  alt="certificate illustration"
                />
                {/* <h3 className="about-goals-item-heading">nostrud Lorem </h3> */}
                <p className="about-goals-item-text mx-auto">
                  To work alongside Colleges of Education to explore
                  credentialing issues with regard to computer science.
                </p>
              </div>
            </div>
          </div>
          </div>
          {/* Timeline title */}
               <div className="about-timeline-box">
               <div className='about-timeline-text'>
                    <h1 className="about-timeline-heading">A Brief <span className="about-highlight"> History </span>
                    {/* <br/> */}
                    Of Computing Teacher</h1>
                    <p className='about-timeline-text'>
                    CSforALL originated in 2013 with CSNYC and grew into the movement we are today.
                    </p>

             
                </div>
              </div>
   
   
          {/* TIMELINE   */}
          <div className='timeline-bg'>
          <VerticalTimeline>
          <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2020"
              contentStyle={{ background: '#ed185b', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #ed185b' }}
              iconStyle={{ background: '#1c58b5', color: '#fff' }}
              icon={<FaLaptopCode />}
            >
              <h3 className="vertical-timeline-element-title">$20 Million of Investments in Schools of Education</h3>
              <h4 className="vertical-timeline-element-subtitle">2021</h4>
              <p>
                  CSforALL is proud to announce, in alignment with the recommendations in the ACT Report,
                  a historic $20 million dollar investment in schools of education at UT El Paso, 
                  University of Florida, Georgia State University, and California State Dominguez Hills. 
              </p>
            </VerticalTimelineElement>
          <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2020"
              contentStyle={{ background: '#1c58b5', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #1c58b5' }}
              iconStyle={{ background: '#1c58b5', color: '#fff' }}
              icon={<FaLaptopCode />}
            >
              <h3 className="vertical-timeline-element-title">Landscaping the Nation</h3>
              <h4 className="vertical-timeline-element-subtitle">2020</h4>
              <p>
              With support from Google's CSER program, the University of Indiana and CSforALL 
              conducted a nationwide survey to determine how CS was taught in schools of 
              education, and created a series of case study examples for other institutions to follow.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2020"
              iconStyle={{ background: '#1c58b5', color: '#fff' }}
              icon={<FaLaptopCode />}
            >
              <h3 className="vertical-timeline-element-title">Funding CSforED</h3>
              <h4 className="vertical-timeline-element-subtitle">2020</h4>
              <p>
              CSforALL was awarded a grant from Google and the NSF to fund the
              Finding a Home for Computer Science Education in Schools of Education project 
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2018"
              iconStyle={{ background: '#1c58b5', color: '#fff' }}
              icon={<FaLaptopCode />}
            >
              <h3 className="vertical-timeline-element-title">Summit 2018</h3>
              <h4 className="vertical-timeline-element-subtitle">2018</h4>
              <div style={{padding:'56.25% 0 0 0',position:'relative'}}>
                <iframe title='CSforED' src="https://player.vimeo.com/video/295498945?color=%23d185b&byline=0&portrait=0" style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} frameBorder="0" allow="fullscreen" allowFullScreen></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
              <p>
                <a href="https://vimeo.com/295498945">CSforALL Summit 2018 - Fireside Chat: CSforALL in Pre-Service Teacher Education</a> from <a href="https://vimeo.com/csforall">CSforALL</a>.
              </p>
              
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2018"
              contentStyle={{ background: '#1c58b5', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #1c58b5' }}
              iconStyle={{ background: '#1c58b5', color: '#fff' }}
              icon={<FaLaptopCode />}
            >
              <h3 className="vertical-timeline-element-title">Priming the Computer Science Teacher Pump</h3>
              <h4 className="vertical-timeline-element-subtitle"> Report 2018</h4>

              <p>
                Funded in part by Microsoft and MongoDB, we released a report summarizing the contributions of the experts 
               from 2017 with specific recommendations for how schools of 
               education could get started.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                   className="vertical-timeline-element--work"
                   date="2017"
                   contentStyle={{ background: '#ed185b', color: '#fff' }}
                   contentArrowStyle={{ borderRight: '7px solid  #ed185b' }}
                   iconStyle={{ background: '#ed185b', color: '#fff' }}
                   icon={<FaLaptopCode />}
            >
              <h3 className="vertical-timeline-element-title">Finding a Home For Computer Science Workshop</h3>
              <h4 className="vertical-timeline-element-subtitle">2017</h4>
              <p>
              We partnered with (Mark, Aman, Joanna, Yasmin) to convene experts 
              in the  national CS ed community about CS in schools of education. 
              Video from presentations and panels of that event.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2016"
              iconStyle={{ background: '#ed185b', color: '#fff' }}
              icon={<FaLaptopCode />}
            >
              <h3 className="vertical-timeline-element-title">CSFA Consortium/ CSforALL</h3>
              <h4 className="vertical-timeline-element-subtitle">2016</h4>
              <p>
              We were funded to work on National level as CSFA consortium. The organization expanded its focus to 
              supporting the national CS education community, and changed its name to CSforALL 
              to represent the full scope of the organization’s programs.
              </p>
            </VerticalTimelineElement>
 
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2016"
              iconStyle={{ background: '#ed185b', color: '#fff' }}
              icon={<FaLaptopCode />}
            >
              <h3 className="vertical-timeline-element-title">Building a Computer Science Teacher Pipeline for New York City</h3>
              <h4 className="vertical-timeline-element-subtitle"> Report 2016</h4>
              <p>
              We produced a report based on 17 schools of education in the NYC area, 
              featuring three programs focused on CS education.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                   className="vertical-timeline-element--work"
                   date="2013"
                   contentStyle={{ background: '#ed185b', color: '#fff' }}
                   contentArrowStyle={{ borderRight: '7px solid  #ed185b' }}
                   iconStyle={{ background: '#ed185b', color: '#fff' }}
                   icon={<FaLaptopCode />}
            >
              <h3 className="vertical-timeline-element-title">CSNYC Founded</h3>
              <h4 className="vertical-timeline-element-subtitle">2013</h4>
              <p>
              The New York City Foundation for Computer Science Education,
               also known as CSNYC, was launched in May of 2013 with a mission 
               to bring computer science education to all of NYC’s 1.1 million students.


              </p>
            </VerticalTimelineElement>
            {/* start at the end */}
            <VerticalTimelineElement
              iconStyle={{ background: '#30bbf8', color: '#fff' }}
              icon={<FaAtom />}
            />
          </VerticalTimeline>
          </div>
 
          {/* end of outer div */}
      </div>
    );
  }
}

export default About;
