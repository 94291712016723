import React, { Component } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import Aman from "../../images/2018-report-organizers/A-Yadav.jpg";
import Joanna from "../../images/2018-report-organizers/J-Goode.jpg";
import Leigh_Ann from "../../images/2018-report-organizers/Leigh_Ann_DeLyser.jpg";
import Mark from "../../images/2018-report-organizers/Mark_Guzdial.jpg";
import Yasmin from "../../images/2018-report-organizers/yasminkafai.jpg";
import '../../styles/organizers.css'
export class Organizers extends Component {
  state = { activeModal: null };

  clickHandler = (e, index) => {
    this.setState({ activeModal: index });
  };

  hideModal = () => {
    this.setState({ activeModal: null });
  };

  render() {
    return (
      <div className="report-2018-organizers">
        <div className="container">
          <h2 className="report-2018-organizers-heading">
            Organizers / Authors
          </h2>
          <div className="report-2018-organizers-container">
            <div className="row">
                <div className="card report-2018-organizers-card mx-auto">
                  <img
                    className="image-size"
                    src={Leigh_Ann}
                    alt=" Organizer Leigh Ann"
                  />
                  <div className="report-2018-card-body">
                    <h5 className="report-2018-card-title">
                      Leigh Ann DeLyser
                    </h5>
                    <p className="report-2018-card-subHeader">CSforALL</p>
                  </div>
                  <p
                    className="report-2018-modal-button "
                    onClick={(e) => this.clickHandler(e, 1)}
                  >
                    Learn More +
                  </p>
                </div>

                <Modal
                  size="lg"
                  id={this.key}
                  show={this.state.activeModal === 1}
                  onHide={this.hideModal}
                >
                  <Modal.Header
                    closeButton
                    className="report-2018-model-header"
                  >
                    <Modal.Title>
                      Leigh Ann DeLyser
                      <p className="report-2018-modal-subHeader">CSforALL</p>
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <Container>
                      <Row className="show-grid">
                        <Col lg={12}>
                          <div>
                            Leigh Ann is a lifelong advocate of computer science
                            education. At CSforALL, Leigh Ann oversees research
                            efforts and advises and implements programs that
                            align with the organization’s strategic goals. Leigh
                            Ann is also a co-founder of the CSforALL Consortium,
                            a national network of CS education content
                            providers, school districts, education associations,
                            and researchers devoted to the mission of CSforALL.
                            Prior to joining CSforALL, Leigh Ann worked for 5
                            years expanding access to CS education for students
                            in NYC, for 10 years as a HS CS teacher, served on
                            the Board of Directors for CSTA, and earned her
                            Ph.D. from Carnegie Mellon University in Computer
                            Science and Cognitive Psychology, with a focus on CS
                            education. Leigh Ann also co-authored the
                            influential Running on Empty report, highlighting
                            the lack of standards in CS education in the United
                            States.
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="close-modal"
                      onClick={() => {
                        this.hideModal();
                      }}
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
                <div className="card report-2018-organizers-card mx-auto">
                  <img
                    className="image-size shift-down"
                    src={Joanna}
                    alt="Organizer Joanna Goode"
                  />
                  <div className="report-2018-card-body">
                    <h5 className="report-2018-card-title">Joanna Goode</h5>
                    <p className="report-2018-card-subHeader">
                      University of Oregon
                    </p>
                  </div>
                  <p
                    className="report-2018-modal-button "
                    onClick={(e) => this.clickHandler(e, 2)}
                  >
                    Learn More +
                  </p>
                </div>

                <Modal
                  size="lg"
                  id={this.key}
                  show={this.state.activeModal === 2}
                  onHide={this.hideModal}
                >
                  <Modal.Header
                    closeButton
                    className="report-2018-model-header"
                  >
                    <Modal.Title>
                      Joanna Goode
                      <p className="report-2018-modal-subHeader">
                        University of Oregon
                      </p>
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <Container>
                      <Row className="show-grid">
                        <Col lg={12}>
                          <div>
                            Joanna Goode is an Associate Professor of Education
                            Studies at the University of Oregon whose
                            scholarship focuses on teacher education and
                            inclusive computer science education. As a former
                            mathematics and computer science high school teacher
                            in an urban high school, she approaches her research
                            with a deep appreciation of how pedagogy,
                            curriculum, and schooling policies converge to
                            influence student learning opportunities. Her
                            NSF-sponsored research examines how large-scale
                            reforms in computer science education can provide
                            innovative opportunities for learning while
                            simultaneously introducing new challenges to equity
                            and inclusion in computing classrooms. Dr. Goode led
                            the development of the equity-focused Exploring
                            Computer Science high school curriculum and
                            professional development program. Joanna Goode has a
                            Ph.D. in Education Division of Urban Schooling from
                            UCLA.
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="close-modal"
                      onClick={() => {
                        this.hideModal();
                      }}
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
                <div className="card report-2018-organizers-card mx-auto">
                  <img
                    className="image-size"
                    src={Mark}
                    alt="Organizer Mark Guzdial"
                  />
                  <div className="report-2018-card-body">
                    <h5 className="report-2018-card-title">Mark Guzdial</h5>
                    <p className="report-2018-card-subHeader">
                      Georgia Institute of Technology
                    </p>
                  </div>
                  <p
                    className="report-2018-modal-button "
                    onClick={(e) => this.clickHandler(e, 3)}
                  >
                    Learn More +
                  </p>
                </div>

                <Modal
                  size="lg"
                  id={this.key}
                  show={this.state.activeModal === 3}
                  onHide={this.hideModal}
                >
                  <Modal.Header
                    closeButton
                    className="report-2018-model-header"
                  >
                    <Modal.Title>
                      Mark Guzdial
                      <p className="report-2018-modal-subHeader">
                        Georgia Institute of Technology
                      </p>
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <Container>
                      <Row className="show-grid">
                        <Col lg={12}>
                          <div>
                            Mark Guzdial is a Professor in the School of
                            Interactive Computing in the College of Computing at
                            Georgia Institute of Technology. He studies how
                            people come to understand computing and how to make
                            that more effective. He leads the CSLearning4U
                            project to create ebooks to help high school
                            teachers learn CS. He is one of the leaders on the
                            NSF alliance “Expanding Computing Education
                            Pathways" which helps US states improve and broaden
                            their computing education. He invented *Media
                            Computation* which uses media as a context for
                            learning computing. With his wife and colleague,
                            Barbara Ericson, he received the 2010 ACM Karl V.
                            Karlstrom Outstanding Educator award. He is an ACM
                            Distinguished Educator and a Fellow of the ACM.
                            Guzdial received his Ph.D. in Computer Science and
                            Education at the University of Michigan.
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="close-modal"
                      onClick={() => {
                        this.hideModal();
                      }}
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
                <div className="card report-2018-organizers-card mx-auto">
                  <img
                    className="image-size shift-down"
                    src={Yasmin}
                    alt="Organizer Yasmin Kafai"
                  />
                  <div className="report-2018-card-body">
                    <h5 className="report-2018-card-title">Yasmin Kafai</h5>
                    <p className="report-2018-card-subHeader">
                      University of Pennsylvania
                    </p>
                    <p
                      className="report-2018-modal-button "
                      onClick={(e) => this.clickHandler(e, 4)}
                    >
                      Learn More +
                    </p>
                  </div>
                </div>

                <Modal
                  size="lg"
                  id={this.key}
                  show={this.state.activeModal === 4}
                  onHide={this.hideModal}
                >
                  <Modal.Header
                    closeButton
                    className="report-2018-model-header"
                  >
                    <Modal.Title>
                      Yasmin Kafai
                      <p className="report-2018-modal-subHeader">
                        University of Pennsylvania
                      </p>
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <Container>
                      <Row className="show-grid">
                        <Col lg={12}>
                          <div>
                            Yasmin B. Kafai, a United States citizen who was
                            born in West Germany, set out to explore the field
                            of digital media and learning, much of it virgin
                            territory in the late 80’s. Researchers had not
                            considered the benefits of designing (rather than
                            playing) digital games for learning. With her
                            pioneering research of children’s learning when
                            programming digital games she helped to launch what
                            two decades later would become the field of serious
                            gaming. Her research empowers students to use
                            computer programming to design games, tell
                            interactive stories, and sew electronic textiles
                            with the goal to support creative expression, build
                            social connections, and broaden participation in
                            computing. She helped develop scratch.mit.edu,
                            called the YouTube of interactive media, where
                            millions of kids create and share their programs and
                            more recently, ecrafting.org, an online community,
                            where everyone can share and celebrate their
                            electronic textile and paper designs. Her
                            award-winning work has received generous funding
                            from the National Science Foundation, the Spencer
                            Foundation, and the MacArthur Foundation.
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="close-modal"
                      onClick={() => {
                        this.hideModal();
                      }}
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
                <div className="card report-2018-organizers-card mx-auto">
                  <img
                    className="image-size"
                    src={Aman}
                    alt="Organizer Aman Yadav"
                  />
                  <div className="report-2018-card-body">
                    <h5 className="report-2018-card-title">Aman Yadav</h5>
                    <p className="report-2018-card-subHeader">
                      Michigan State University
                    </p>
                  </div>
                  <p
                    className="report-2018-modal-button "
                    onClick={(e) => this.clickHandler(e, 5)}
                  >
                    Learn More +
                  </p>
                </div>

                <Modal
                  size="lg"
                  id={this.key}
                  show={this.state.activeModal === 5}
                  onHide={this.hideModal}
                >
                  <Modal.Header
                    closeButton
                    className="report-2018-model-header"
                  >
                    <Modal.Title>
                      Aman Yadav
                      <p className="report-2018-modal-subHeader">
                        Michigan State University
                      </p>
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <Container>
                      <Row className="show-grid">
                        <Col lg={12}>
                          <div>
                            Dr. Aman Yadav is an Associate Professor and
                            Director of Masters of Arts in Educational
                            Technology program at Michigan State University. Dr.
                            Yadav’s work focuses on supporting preservice and
                            inservice teachers to develop knowledge on how to
                            teach computer science concepts at the primary and
                            secondary level. His work has been published in a
                            number of leading journals, including The ACM
                            Transactions on Computing Education, Journal of
                            Engineering Education, and Communications of the
                            ACM.
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="close-modal"
                      onClick={() => {
                        this.hideModal();
                      }}
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>

              </div>
            </div>
          </div>
    );
  }
}

export default Organizers;
