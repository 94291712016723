import React, { Component } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import "../styles/Contact.css";
import { firebase } from "../firebaseConfig/firebase";
import { Link } from "react-router-dom";

// import Axios from "axios";


const db = firebase.database();
 

export class Contact extends Component {
  state = {
    name: "",
    message: "",
    email: "",
    subject: "",
    sent: false,
    buttonText: "Send Message",
  };
  formSubmit = (e) => {
    e.preventDefault();

    this.setState({sent: true});

    let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      subject: this.state.subject,
    };

    // Change firebase db target depending on environment. 
    var dbTarget;
    if(process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'staging'){
      dbTarget = 'messages-stage';
    } else if (process.env.REACT_APP_ENV === 'prod'){
      dbTarget = 'messages';
    } else {
      console.log("Error - no valid environment detected")
    }

    db.ref().child(dbTarget).push({
    
      name: data.name,
      email: data.email,
      message: data.message,
      subject: data.subject,
      time: new Date(),
  })
  .then(function() {
      console.log("Saved email to db ");
  })
  .catch(function(error) {
      console.error("Error adding document: ", error);
  });
  };

   resetForm = () => {
    this.setState({
      name: "",
      message: "",
      subject: "",
      email: "",
      buttonText: "Send Message",
    });
  };
  render() {
    let contact;
    if (this.state.sent){
      contact = <Container>
        <Row
        className="contact-row-container"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "auto" }}
        >
          <h2 className="contact-header" >Message sent!</h2>
          <div className="contact-message-info">
            <p>Thank you for contacting us. We will be in touch.</p>
            <p><Link to="/">return to home</Link></p>
          </div>
        </Col>
      </Row>
      </Container>;

    } else {
      contact = <Container>
      <Row
        className="contact-row-container"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{ marginBottom: "auto" }}
        >
          <h2 className="contact-header">Send us a message!</h2>
          <div className="contact-message-info">
            <p>
              Are you a teacher preparation program? Would you like to appear on the <Link to="/#pinsBtn">data display</Link>?
            </p>
            <p>
              
            </p>

            <b>Email</b>
            <p className="contact-email">info@csforall.org</p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Form onSubmit={(e) => this.formSubmit(e)}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Name*</Form.Label>

                  <Form.Control
                    onChange={(e) =>
                      this.setState({ name: e.target.value })
                    }
                    name="name"
                    type="text"
                    placeholder="Enter your name"
                    value={this.state.name}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email*</Form.Label>

                  <Form.Control
                    onChange={(e) =>
                      this.setState({ email: e.target.value })
                    }
                    name="email"
                    type="email"
                    placeholder="your@email.com"
                    required
                    value={this.state.email}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formSubject">
                  <Form.Label>Subject*</Form.Label>

                  <Form.Control
                    onChange={(e) =>
                      this.setState({ subject: e.target.value })
                    }
                    name="subject"
                    type="text"
                    placeholder="Subject"
                    value={this.state.subject}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="formMessage">
              <Form.Label>Message*</Form.Label>

              <Form.Control
                as="textarea"
                row="6"
                onChange={(e) => this.setState({ message: e.target.value })}
                name="message"
                type="text"
                placeholder="Please write your message here"
                value={this.state.message}
                required
              />
            </Form.Group>

            <button type="submit" className="primary-button">
              {this.state.buttonText}
            </button>
          </Form>
        </Col>
      </Row>
    </Container>;
    }

    return (
      <div className="contact-container">
        {contact}
      </div>
    );
  }
}

export default Contact;
