import React, { Component } from "react";

import MainGraphic from "../../images/Main-Graphic.png";
import "../../styles/Report2018.css";

import csforallLogo from "../../images/logos/csforall-logo.svg";
import microsoftLogo from "../../images/logos/Microsoft-logo.svg";
import mongoDBLogo from "../../images/logos/mongoDB-logo.svg";
import nsfLogo from "../../images/logos/nsf-logo.png";

import FocusAreasAccordion from "../../components/2018-Report/FocusAreasAccordion";
import FocusAreaCards from "../../components/2018-Report/FocusAreaCards";
import Organizers from "../../components/2018-Report/Organizers";

export class Report2018 extends Component {
  render() {
    return (
      <div>
        <div className="report-2018-hero">
          <div className=" container-width">
            <div className="row">
              <div className="col-lg-5 col-md-12 report-2018-hero-content-container-left">
                <h1 className="report-2018-hero-header">
                  Finding a Home for Computer Science in Schools of Education
                </h1>
                <h4 className="report-2018-hero-subHeader">
                  Integrating Computer Science Education into Schools of
                  Education
                </h4>
                <p className="report-2018-hero-miniHeader">
                  AERA Report Launch April, 12, 2018
                </p>
                <a
                  target="blank"
                  className="CTA-button"
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2FPrimingthePump%20Preservice%20Report%20Final.pdf?alt=media&token=20ba6834-6c44-423d-8b3c-1eb372460ee5"
                >
                  Download Report
                </a>
              </div>
              <div className="col-lg-7 d-none d-lg-block report-2018-hero-content-container-right">
                <div className="row">
                  <div className="col-6 ">
                    <img
                      src='https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fpeople6.jpg?alt=media&token=4420ae70-cb6d-46d2-9c2f-5067f7ff2412'
                      className="report-2018-hero-image"
                      alt="report-2018-text-companion"
                    />
                  </div>
                  <div className="col-6 ">
                    <img
                      src='https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fpeople5.jpg?alt=media&token=efc7c5d8-644c-4ca0-a237-f3a061a88131'
                      className="report-2018-hero-image2"
                      alt="report-2016-text-companion"
                    />
                  </div>
                </div>
                {/* <div className="row"> */}
                  <div className="col-8 ">
                    <img
                      src='https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fpeople8.jpg?alt=media&token=ab2d69ab-c740-491d-9625-36f59ca54c3e'
                      className="report-2018-hero-image3"
                      alt="workshop-2017-text-companion"
                    />
                  </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* I N F O  S E C T I O N  */}
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row report-2018-intro-content">
            <div className="col-lg-6">
              <h1 className="report-2018-intro-heading">Overview</h1>

              <p className="report-2018-intro-text">
                This report focuses on schools of education rather than
                departments or colleges of computer science for setting up
                computer science teacher education.
              </p>
              <p className="report-2018-intro-text">
                We challenge US teacher education programs to innovate and
                integrate a new discipline into their programs. What we propose
                is nothing less than a change to the American Education canon.
                Such enormous change will require innovating in different ways,
                using different models and strategies, before we find models
                that work. The report, Priming the Computer Science Teacher
                Pump, will highlight examples of integration from across the
                United States, and provide concrete recommendations for
                discussion.
              </p>
              <p className="report-2018-intro-text">
                With the expansion of computer science education in mainstream
                K-12 schools, the current training mechanisms for teachers
                quickly will fall short of supporting a sustainable pipeline of
                teachers for the scale many cities and states have committed to.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src='https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fpeople6.jpg?alt=media&token=4420ae70-cb6d-46d2-9c2f-5067f7ff2412'
                className="report-2018-intro-image"
                alt="A group of 8 summit attendees sit in chairs in a circle having a breakout discussion"
              />
            </div>
          </div>
        </div>
        {/* G R A P H I  S E C T I O N  */}
        <div className="report-2018-graphic-section ">
          <div className="container">
            <h2 className="text-center report-2018-section-title">
              Recommendations from Priming the Computer Science Teacher Pump
            </h2>
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="report-2018-graphic-section-image"
                  src={MainGraphic}
                  alt=""
                />
              </div>
              <div className="col-lg-6">
                <FocusAreasAccordion />
              </div>
            </div>
          </div>
        </div>
        <Organizers />
        <div className="report-2018-supporters-container">
          <div className="container-fluid">
            <h2 className="text-center report-2018-section-title">
              Supporters
            </h2>
            <div className="row report-2018-supporters ">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                <img src={csforallLogo} alt="CSForAll Logo" />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                <img src={nsfLogo} alt="NSF Logo" />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                <img src={microsoftLogo} alt="Microsoft Logo" />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                <img src={mongoDBLogo} alt="MongoDB Logo" />
              </div>
            </div>
          </div>
        </div>
        <FocusAreaCards />
      </div>
    );
  }
}

export default Report2018;
