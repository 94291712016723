import React from "react";

import focusArea1 from "../../images/focus-areas-icons/focus1.png";
import focusArea2 from "../../images/focus-areas-icons/focus2.png";
import focusArea3 from "../../images/focus-areas-icons/focus3.png";
import focusArea4 from "../../images/focus-areas-icons/focus4.png";
import focusArea5 from "../../images/focus-areas-icons/focus5.png";
import focusArea6 from "../../images/focus-areas-icons/focus6.png";
import focusArea7 from "../../images/focus-areas-icons/focus7.png";
import focusArea8 from "../../images/focus-areas-icons/focus8.png";

import { Button } from "react-bootstrap";

function FocusAreaCards() {
  return (
    <div className="report-2018-cards-container">
      <div className="container">
        <h2 className="text-center report-2018-section-title">
          Download Links to the Focus Area Posters and Postcards
        </h2>
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card text-center mx-auto d-block report-2018-focus-area-card">
              <img
                className="card-img-top mx-auto d-block focus-area-card-image card-odd"
                src={focusArea1}
                alt="focus area 1 icon"
              />
              <div className="card-body">
                <h5 className="report-2018-card-title ">Focus Area 1</h5>
                <p className="report-2018-card-text">
                  What Teachers Need to Know about CS
                </p>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-postcards%2FFocus%20Area%201.pdf?alt=media&token=e2d7a98c-523d-4a03-b6c0-2fa0a422ce5c"
                  className="focus-area-card-button"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Postcard
                </Button>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-posters%2FFocus%20Area%201.pdf?alt=media&token=0001b8f8-3c57-4a5c-a320-dd702a3093c5"
                  className="focus-area-card-button"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Poster
                </Button>
              </div>
              <div className="bottom-border-odd"></div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card text-center mx-auto d-block report-2018-focus-area-card ">
              <img
                className="card-img-top mx-auto d-block focus-area-card-image card-even"
                src={focusArea2}
                alt="focus area 2 icon"
              />

              <div className="card-body">
                <h5 className="report-2018-card-title ">Focus Area 2</h5>
                <p className="report-2018-card-text">
                  Teacher Development Models for CS Education
                </p>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-postcards%2FFocus%20Area%202.pdf?alt=media&token=4b60cc6a-36da-4e0a-91a6-773eb5981544"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Postcard
                </Button>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-posters%2FFocus%20Area%202.pdf?alt=media&token=da42f4e8-fdf0-429f-a959-d9bf0b1be270"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Poster
                </Button>
              </div>
              <div className="bottom-border-even"></div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card text-center mx-auto d-block report-2018-focus-area-card ">
              <img
                className="card-img-top mx-auto d-block focus-area-card-image card-odd"
                src={focusArea3}
                alt="focus area 3 icon"
              />
              <div className="card-body">
                <h5 className="report-2018-card-title">Focus Area 3</h5>
                <p className="report-2018-card-text">
                  CS Education in Teacher Education
                </p>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-postcards%2FFocus%20Area%203.pdf?alt=media&token=6427211e-597d-42ae-bbc2-d7fe15710673"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Postcard
                </Button>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-posters%2FFocus%20Area%203.pdf?alt=media&token=6b8bf28d-a4ab-4f83-9fd3-07a2fe8216f6"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Poster
                </Button>
              </div>
              <div className="bottom-border-odd"></div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card text-center mx-auto d-block report-2018-focus-area-card ">
              <img
                className="card-img-top mx-auto d-block focus-area-card-image card-even"
                src={focusArea4}
                alt="focus area 4 icon"
              />

              <div className="card-body">
                <h5 className="report-2018-card-title">Focus Area 4</h5>
                <p className="report-2018-card-text">
                  Standalone CS Education in K-12
                </p>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-postcards%2FFocus%20Area%204.pdf?alt=media&token=1b970866-30f1-46e4-9106-b22dd02c055b"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                  variant="primary"
                >
                  Postcard
                </Button>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-posters%2FFocus%20Area%204.pdf?alt=media&token=590b822e-a93c-4a60-b959-85a4e0f33e00"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                  variant="primary"

                >
                  Poster
                </Button>
              </div>
              <div className="bottom-border-even"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card text-center mx-auto d-block report-2018-focus-area-card ">
              <img
                className="card-img-top mx-auto d-block focus-area-card-image card-odd"
                src={focusArea5}
                alt="focus area 5 icon"
              />
              <div className="card-body">
                <h5 className="report-2018-card-title">Focus Area 5</h5>
                <p className="report-2018-card-text">
                  Integrating CS Education into Other Disciplines
                </p>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-postcards%2FFocus%20Area%205.pdf?alt=media&token=d7737d81-e0e4-4cf2-ac66-c7851bb6622b"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Postcard
                </Button>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-posters%2FFocus%20Area%205.pdf?alt=media&token=2c2bb713-978f-47eb-a10f-f597522919f1"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Poster
                </Button>
              </div>
              <div className="bottom-border-odd"></div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card text-center mx-auto d-block report-2018-focus-area-card ">
              <img
                className="card-img-top mx-auto d-block focus-area-card-image card-even"
                src={focusArea6}
                alt="focus area 6 icon"
              />

              <div className="card-body">
                <h5 className="report-2018-card-title">Focus Area 6</h5>
                <p className="report-2018-card-text">
                  CS Education in Grades K-8
                </p>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-postcards%2FFocus%20Area%206.pdf?alt=media&token=8dde5501-49fe-445a-bfa0-11f9da6f3490"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Postcard
                </Button>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-posters%2FFocus%20Area%206.pdf?alt=media&token=e78e5807-e278-465d-8933-5ea5f03f9fe7"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Poster
                </Button>
              </div>
              <div className="bottom-border-even"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card text-center mx-auto d-block report-2018-focus-area-card ">
              <img
                className="card-img-top mx-auto d-block focus-area-card-image card-odd"
                src={focusArea7}
                alt="focus area 7 icon"
              />
              <div className="card-body">
                <h5 className="report-2018-card-title">Focus Area 7</h5>
                <p className="report-2018-card-text">
                  Preparing Educational Leaders to Support CS Education
                </p>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-postcards%2FFocus%20Area%207.pdf?alt=media&token=36a86b65-4b15-4576-9791-10f6e4b1617b"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Postcard
                </Button>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-posters%2FFocus%20Area%207.pdf?alt=media&token=f32075f9-bc90-4025-83e6-033c0d2e6b27"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Poster
                </Button>
              </div>
              <div className="bottom-border-odd"></div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card text-center mx-auto d-block report-2018-focus-area-card">
              <img
                className="card-img-top mx-auto d-block focus-area-card-image  card-even"
                src={focusArea8}
                alt="focus area 8 icon"
              />

              <div className="card-body">
                <h5 className="report-2018-card-title">Focus Area 8</h5>
                <p className="report-2018-card-text">
                  Creating the CS Education PhD Pipeline
                </p>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-postcards%2FFocus%20Area%208.pdf?alt=media&token=9f3638cc-c3a9-4db1-8cf3-02b656ec951d"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Postcard
                </Button>
                <Button
                  href="https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/pdf%2Ffocus-area-posters%2FFocus%20Area%208.pdf?alt=media&token=8c08de6b-9ba5-4a20-be90-0e0ac4c0ed1a"
                  target="_blank"
                  className="focus-area-card-button"
                  rel="noopener noreferrer"
                  size="lg"
                >
                  Poster
                </Button>
              </div>
              <div className="bottom-border-even"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FocusAreaCards;
