import React, { Component } from "react";
import Headroom from "react-headroom";

import { NavDropdown, Navbar,Nav } from "react-bootstrap";
import "../styles/Navigation.css";
import CSforEDLogo from "../images/logos/csforedLogo.svg";
export class Navigation extends Component {
  render() {
    return (
      <Headroom>
        <Navbar  bg="light" expand="md" >
        <Navbar.Brand href="#">
            <img className="logo-nav" src={CSforEDLogo} alt="Summit Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
        <Nav
        className="ml-auto my-2 my-lg-0"
        style={{ maxHeight: '200px' }}
        navbarScroll
      >
          <Nav.Link className='bold padding-left' href="/">Home</Nav.Link>
              
            <NavDropdown className='bold padding-left' title="Resources">
              <NavDropdown.Item
                  className="dropdown-link"
                  onClick={this.toggleMenu}
                  href="/report2018"
                >
                <b>2018 -</b> Priming the Computer <br/> Science Teacher Pump
              </NavDropdown.Item>
              <NavDropdown.Divider />
              
              <NavDropdown.Item
                  className="dropdown-link"
                  onClick={this.toggleMenu}
                  href="/workshop2017"
                >
                  <b>2017 - </b> Home for CS Workshop
              </NavDropdown.Item>
              <NavDropdown.Divider />
              
              <NavDropdown.Item
                  className="dropdown-link"
                  onClick={this.toggleMenu}
                  href="/report2016"
              >
                  <b>2016 -</b> Building a CS Teacher Pipeline 
              </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link className='bold padding-left' href="/learn-more">Learn More</Nav.Link>

              <Nav.Link className='bold padding-left' href="/contact">Contact</Nav.Link>
              </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Headroom>
    );
  }
}

export default Navigation;
