import React, { Component } from "react";
import "../styles/Home.css";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import MapGL, {
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
} from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import $ from 'jquery';

// university images
import csu from '../images/logos/universities/csu.png';
import gstate from '../images/logos/universities/gsu.jpg';
import uf from '../images/logos/universities/uf.png';
import utep from '../images/logos/universities/utep.png';

 
//imports for maps
import Pins from './mapData/pins';
import ControlPanel from './mapData/control-panel';
import InstitutionInfo from './mapData/InstitutionInfo.jsx';
import Institutions from './mapData/institutions-located.json';
 
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
console.log(MAPBOX_TOKEN)
// Iframe class start
const mapIframe = {
  d3Vis:
    '<iframe frameBorder="0" scrolling="no" seamless="seamless" class="map-iframe" src="https://csfored-vis.web.app"></iframe>',
};

class Iframe extends Component {

  render() {
      function Iframe(props) {
    return (
      <div className='iframe-center'
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
  }

    return (
      <div className="iframe-containter">
        <Iframe iframe={mapIframe["d3Vis"]} allow="autoplay" />
      </div>
    );
  }
}
// iframe class end

// mapbox class
//mapbox navigation controls
const fullscreenControlStyle = {
  position: 'absolute',
  top: 0,
  right: 35,
  padding: '10px'
};

const navStyle = {
  position: 'absolute',
  top: 36,
  left: 0,
  padding: '10px'
};

const scaleControlStyle = {
  position: 'absolute',
  bottom: 32,
  right: 0,
  padding: '10px'
};
 
// const Demo = () => {
//   const ref = useRef(null);
//   useClickAway(ref, () => {
//     console.log('OUTSIDE CLICKED');
//   });

//   return (
//     <div ref={ref} style={{
//       width: 200,
//       height: 200,
//       background: 'red',
//     }} />
//   );
// };
class Mapbox extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 38,
        longitude: -95,
        zoom: 3.29,
        bearing: 0,
        pitch: 0
      },
      popupInfo: null
    };
  }
  _updateViewport = viewport => {
    this.setState({viewport});
  };

  _onClickMarker = city => {
    this.setState({popupInfo: city});
  };
 
  _renderPopup() { 
    const {popupInfo} = this.state;
    return (
      popupInfo && (
           <Popup
            tipSize={1}
            dynamicPosition={true}
            longitude={popupInfo.long}
            latitude={popupInfo.lat}
            closeButton={true}
            closeOnClick={true}
            onClose={() => this.setState({popupInfo: null})}
          >
            <InstitutionInfo info={popupInfo} />
          </Popup>
       )
    );

  }
  render() {
    const {viewport} = this.state;
  return(     
  
  <div className="map-container">
  <MapGL 
  {...viewport}
  width="100%"
  height="70vh"
  mapStyle="mapbox://styles/csforall/ckdy3o4i62vef19pgnqdtk0lo"
  onViewportChange={this._updateViewport}
  mapboxApiAccessToken={MAPBOX_TOKEN}
>
   <Pins data={Institutions} onClick={this._onClickMarker} />
    {this._renderPopup()}

  <div style={fullscreenControlStyle}>
  <FullscreenControl />
</div>
<div style={navStyle}>
  <NavigationControl />
</div>
<div style={scaleControlStyle}>
  <ScaleControl />
</div>
<ControlPanel containerComponent={this.props.containerComponent} />

</MapGL>
</div>
);
}
}



const DataContainer = () => {
  $(".map-btn").click(function() {
    $("button.active").removeClass("active");
    $(this).addClass("active");
  });

  const [showPins, setShowPins] = React.useState(false);
   var pinsClick = () => {
     setShowPins(true);
     setShowData(false);
    //  console.log('PINS CLICK INSIDE');
    
     $("button.active").removeClass("active");
     $('#pinsBtn').addClass("active");
   
    }
 
   const [showData, setShowData] = React.useState(true);
   var dataClick = () => {
     setShowPins(false);
     setShowData(true);
     console.log('DATA CLICK INSIDE');

     $("button.active").removeClass("active");
    $('#dataBtn').addClass("active");
   }


  return (
      <div className='iframe-container'>
        <div className="map-btn-container">
          <div className='map-btns-container'>
            <button value="DataContainer" id='pinsBtn' className="map-btn-left map-btn" onClick={pinsClick}>Institution View</button>
            <button value="DataContainer" id='dataBtn' className="map-btn-right map-btn active" onClick={dataClick}>State View</button>      
          </div>
          <Link className="map-btn-states " to="/states-pdfs">Individual State Reports</Link>
      </div>
       
        <div className="spacer"></div>
        { showData ? <Iframe /> : null }
        { showPins ? <Mapbox /> : null }
    </div>
  )
}


export class HomePage extends Component {
  // componentWillMount(){

    // // add lat and long data to zip info
    // CITIES.map(function(data){
    //   console.log(data);
    // var zipCode = data.zip
    //  $.getJSON("https://maps.googleapis.com/maps/api/geocode/json?address="+ zipCode +"&key="+geocodingAPI, function (json){
    //  var longitude = json.results[0].geometry.location.lng;
    //  var latitude = json.results[0].geometry.location.lat;
    //  data['lat'] = latitude;
    //  data['long'] = longitude;
 
    // })
    // citiesWithLL.push(data);

    // return citiesWithLL;
    // });
  // }
 render() {

    return (
      <div>
        <div className="home-hero">
          <div style={{ background: "none" }} className="jumbotron">
            <h2 className="home-hero-text">
              Embedding Computer Science Education <br/>in Teacher Preparation
            </h2>
          </div>
        </div>
        
        {/* Q U O T E  S E C T I O N */}
        <div className="home-quote-section">
          <div className="container">
            <div className="row">
              <div className="home-quote">
                <p className='home-quote-title'>Schools of education are <span className="home-quote-highlighted-area">not </span>{" "}preparing teachers for the classrooms of today</p>
                <p className='home-quote-subtitle'>Today’s classrooms include computer science. 34 states now have mandated computer science standards required for all students.</p>
              </div>
            </div>
          </div>
        </div>

        {/* M A P S E C T I O N */}
        {/* <div className="spacer"></div> */}
        <div>
          <h3 className='map-title'>
            How are teachers prepared for Computer Science and Computational Thinking in your state?
          </h3>
        </div>
        <DataContainer />
         {/* <div className="spacer"></div> */}

         {/* UNIVERSITY LOGO SECTION */}
        <div className="uni-logo-section">
            <div className="row">
              <div className="col-12">

                <div className='logo-wall logo-wall-container'>
                    <a href="https://www.csudh.edu/" rel='noopener noreferrer' target="_blank"><img src={csu} alt="California State University, Dominguez Hills Logo and Link to their website" /></a>
                    <a href="https://www.gsu.edu/" rel='noopener noreferrer' target="_blank"><img src={gstate} alt="Georgia State University Logo and Link to their website" /></a>
                    <a href="https://www.ufl.edu/" rel='noopener noreferrer' target="_blank"><img src={uf} class='florida' alt="University of Florida Logo and Link to their website" /></a>
                    <a href="https://www.utep.edu/" rel='noopener noreferrer' target="_blank"><img src={utep} alt="University of Texas at El Paso Logo and Link to their website" /></a>
                </div>

                <div class='logo-wall-subtitle'>
                  <p> CSforALL is proud to announce, in alignment with the recommendations in the ACT Report,
                  a historic $20 million dollar investment in schools of education at UT El Paso, 
                  University of Florida, Georgia State University, and California State Dominguez Hills. 
                  </p>
                  <a href="https://medium.com/@csforall/investing-in-teacher-preparation-for-the-classrooms-of-today-c7dd7c41ab68" rel='noopener noreferrer' className="primary-button" target='_blank'>Check out the Announcement</a>
                </div>

              </div>
            </div>
            </div>


        {/* I N T R O   S E C T I O N */}
        <div className="spacer"></div>
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="home-vision-box">
                <h1 className="home-intro-heading">The Vision</h1>
                <div className="home-intro-content">
                  <p>
                    
                    <span className="home-intro-content-highlight-area">
                    CSforED</span> is a home for resources for schools of education, 
                    and builds on work funded by the National Science 
                    Foundation and Google to develop a series of data 
                    tools, resources, and case studies highlighting the 
                    integration of CS education into teacher preparation 
                    programs in the United States. 
                    <br/>
                    <br/> 
                    Computer science and computational thinking is no longer just
                    a subject for a single specialized or interested teacher,
                    teachers of various grades and disciplines are being asked to
                    use Computer science as a tool to help students become the
                    problem solvers our world needs for tomorrow.
                    <br/>
                    <br/>
                    This website brings together resources for education leaders and 
                    stakeholders looking to identify programs with CS Education 
                    components or resources and recommendations for how they can 
                    improve their own programs.

                  </p>
                  <Link className="primary-button" to="/learn-more">
                    Find out more
                  </Link>
                </div>
              </div>
            </div>
          </div>
 

      {/* INFO SECTION */}
      <div className="hero-info-section-image"></div>
        <div className="container">
          <div className="hero-info-section">
            <div className="row ">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <p className="hero-info-sec2-left">
                  
                Teacher preparation is an issue of equity
                </p>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <p className="hero-info-sec-right">
                  Computer science and computational thinking cannot just be for
                  the schools and districts who can afford additional
                  professional learning, but should be a part of every teacher’s
                  toolbox, so that a student in the bronx, inner city Chicago,
                  or Los Angeles has the same highly qualified teacher prepared
                  to teach problem solving and critical thinking with computer
                  science as a student in the more affluent suburbs.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* R E S O U R C E S */}
        <div className="resources">
          <div className="container resources-wrapper">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="resource-sec-title">Resources</h2>
              </div>
            </div>

            {/* 2018 report */}
            <div className="home-resource">
              <div className="row">
                <Fade bottom>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <img
                      className="home-resource-image"
                      src='https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fpost-its.jpg?alt=media&token=6336333b-36c8-4cf6-827a-5eb630117b22'
                      alt="2018 Report "
                    />
                  </div>
                </Fade>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <Fade bottom>
                    <div className="home-resource-content-container">
                      <h3 className="home-resource-heading">Priming the Computer Science Teacher Pump</h3>
                      <p className='subtitle'>
                        2018 Report
                      </p>
                      <p className="home-resource-text">
                      The report, Priming the Computer Science Teacher Pump, highlights examples of integration in 
                      US teacher education programs to innovate and integrate a new discipline into their programs.
                      </p>
                      <div>
                        <Link className="CTA-button" to="/report2018">
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            {/* 2017 workshop */}
            <div className="home-resource">
              <div className="row flex-column-reverse flex-lg-row">
                <div className="col-lg-6 ">
                  <Fade bottom>
                    <div className="home-resource-content-container">
                      <h3 className="home-resource-heading">
                        Finding a Home For Computer Science Workshop
                      </h3>
                      <p className='subtitle'>
                        2017 Workshop
                      </p>
                      <p className="home-resource-text">
                      Access video resources from speakers and panelist in 2017 about how computer 
                      science education can fit within schools of education.
                      </p>
                      <div>
                        <Link className="CTA-button" to="/workshop2017">
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </Fade>
                </div>
                <Fade bottom>
                  <div className="col-lg-6 ">
                    <img
                      className="home-resource-image"
                      src='https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fpeople7.jpg?alt=media&token=98c99902-7fce-45e9-81d0-431c7a36521d'
                      alt="Workshop 2017 "
                    />
                  </div>
                </Fade>
              </div>
            </div>
            {/* 2016 report */}
            <div className="home-resource">
              <div className="row">
                <Fade bottom>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <img
                      className="home-resource-image"
                      src='https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fteach-cs.jpg?alt=media&token=89dc916c-d1c2-45b9-b1d0-9e5956abb222'
                      alt="2016 Report "
                    />
                  </div>
                </Fade>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <Fade bottom>
                    <div className="home-resource-content-container">
                      <h3 className="home-resource-heading">Building a Computer Science Teacher Pipeline for New York City</h3>
                      <p className='subtitle'>
                        2016 Report
                      </p>
                      <p className="home-resource-text">
                      The Building a Computer Science Teacher Pipeline for New York City report, 
                      highlights existing programs that have been designed by our local CS 
                      education community in response to the needs of schools and teachers. 
                      These programs suggest a range of promising models.  
                       </p>
                      <div>
                        <Link className="CTA-button" to="/report2016">
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* A B O U T  P A G E  C T A */}
        <div className="home-about-page-CTA-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="text-center home-about-page-CTA-section-content">
                  <h3 className="home-about-page-CTA-section-title">
                    Learn more about CSforALL
                  </h3>
                  <a className="primary-button" rel="noopener noreferrer" target='_blank' href='https://www.csforall.org/about/'>
                    The Mission
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
// export function renderToDom(container) {
//   render(<HomePage />, container);
// }