import * as React from 'react';
import {PureComponent} from 'react';
import "../../styles/InstitutionInfo.css";
export default class InstitutionInfo extends PureComponent {
  render() {
    const {info} = this.props;
    const displayName = `${info.name}`;
    const city = `${info.city}`;
    const website = `${info.url}`;
    const elem = info.elementary;
    const spec = info.specialist;
    let haveProgram = false;

    var elemDisplay;
    var specDisplay;

    // elementary
    if (elem === true){
      elemDisplay = <span>Elementary{ (spec & elem) ? ',' : '' }</span>;
    } else {
      elemDisplay = <span></span>;
    }
    // specialized
    if (spec === true){
      specDisplay = <span>Specialized</span>;
    } else {
      specDisplay = <span></span>;
    }

    if (!spec && !elem) {
      haveProgram = true;
    }

    return (
        <div className={`popupStyle ${(haveProgram ? 'noProgram' : 'withProgram')}`}>
          {/* <img width={240} src={info.image} alt={displayName}/> */}
          <h3 className='title-width'>
            <a
              target="_new"
              href={`${website}`}
            >
              {displayName}
            </a>
            <p className='subtext city'>{city}</p>
          </h3>
          
          <div>
            { 
              haveProgram 
              ? <p className='text-bold programs-text'>No Programs Reported</p>
              : <p className='text-bold programs-text'>Programs offered: {elemDisplay} {specDisplay} </p>
            }
          </div>

          <a
            target="_new"
            href={`${website}`}
            className='btn'
          >
            Learn more
          </a>
         
        </div>
    );
  }
}