import * as React from 'react';
import {PureComponent} from 'react';
import {Marker} from 'react-map-gl';
// import pins json here!

// const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
//   c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
//   C20.1,15.8,20.2,15.8,20.2,15.7z`;
// const ICON = `M 7 -11 C 18 -32 -18 -32 -7 -11 L 0 0 L 7 -11z`;
// const ICON = `M 11 -22 C 12 -39 -12 -39 -11 -22 C -10 -14 -3 -14 0 0 C 3 -14 10 -14 11 -22 z`
const ICON = `M 11 -22 C 12 -39 -12 -39 -11 -22 C -10 -14 -3 -14 0 0 C 3 -14 10 -14 11 -22 Z L 4 -22 C 4 -17 -4 -17 -4 -22 C -4 -28 4 -28 4 -22`
const WIDTH = 24;
const HEIGHT = 30;
//  TO DO: on load some lat longs are off from where the pins are on the map
// Important for perf: the markers never change, avoid rerender when the map viewport changes
export default class Pins extends PureComponent {
   render() {
  const {data, onClick} = this.props;
  // console.log(onClick);

    return data.map((city, index) => (
      <Marker key={`marker-${index}`} longitude={city.long} latitude={city.lat}>
        <svg
          // height={WIDTH}
          // viewBox = '0 0 24 24'
          viewBox="-12 -35 24 35"
          height = {HEIGHT}
          style={{
            cursor: 'context-menu',
            fill: '#ed185b',
            stroke: 'none',
            transform: `translate(${-WIDTH}px,${-HEIGHT}px)`
          }}
          onClick={() => onClick(city)}
        >
          <path d={ICON} />
        </svg>
      </Marker>
    ));
    
  }
  
}