import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Report2018 from "./pages/reports/Report2018";
import Report2016 from "./pages/reports/Report2016";
import Workshop2017 from "./pages/workshops/Workshop2017";
import StatesPdfs from "./pages/StatePdfs";
 
import { firebase } from "./firebaseConfig/firebase";

// unused but necessary for analytics collection? 
const analytics = firebase.analytics();

function App() {
  return (
    <Router>
      <ScrollToTop />

      <Navigation />
      <Switch>
        <Route path="/learn-more" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/report2018" component={Report2018} />
        <Route path="/report2016" component={Report2016} />
        <Route path="/workshop2017" component={Workshop2017} />
        <Route path="/states-pdfs" component={StatesPdfs} />

        <Route path="/" component={HomePage} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
