const focusAreas = {
  focusArea1: {
    name: "Focus Area 1",
    paragraph1:
      "Schools of education should include content, teaching methods, and state standards for computer science in any preparation programs for teachers who may have responsibility for those standards.",
    paragraph2:
      "Faculty designing new courses or programs should partner with practitioners to make sure coursework aligns with any relevant offerings in regional schools.",
    paragraph3:
      "Faculty in schools of education should regularly connect with the computer science teacher community of practice events and computer science education research publications in order to include current best practices in coursework.",
  },
  focusArea2: {
    name: "Focus Area 2",
    paragraph1:
      "Faculty of education can connect with members of the departments of computer science who are running professional development or inservice teacher preparation to identify needs and best practices.",
    paragraph2:
      "Faculty preparing courses for future teachers should review example syllabi or program outlines from other institutions for common practices.",
    paragraph3:
      "Schools of education should make sure teacher candidates have content preparation aligned with national and relevant state standards for computer science education.",
  },
  focusArea3: {
    name: "Focus Area 3",
    paragraph1:
      "Schools of education can commit tenure-track faculty lines and resources in collaboration with departments of computer science to support teaching and research in computer science education.",
    paragraph2:
      "Schools of education can hire tenure-track faculty who work in computing education research into STEM education lines and encourage their collaboration with computer science faculty.",
    paragraph3:
      "Schools of education can count computer science education research published in conference proceedings at the same level as journal publications to allow junior faculty to get promoted and tenured.",
  },
  focusArea4: {
    name: "Focus Area 4",
    paragraph1:
      "Schools of education should explore offering graduate coursework, certificate programs, or even master’s degrees in computer science education. These programs should seek to align with state certification efforts to enable teachers who are new to computer science to obtain any necessary licenses.",
    paragraph2:
      "Secondary teacher education programs need to develop and provide courses for preparing teachers with experiences and background in teaching introductory and Advanced Placement computer science courses.",
    paragraph3:
      "Teacher education programs should hire computer science education specialists, just like they have subject matter specialists for science, mathematics, or literacy topics, who not only teach courses but can also supervise and mentor pre-service elementary and secondary teachers.",
  },
  focusArea5: {
    name: "Focus Area 5",
    paragraph1:
      "Schools of education should identify examples of integrated computer science education to include in coursework for education students in other disciplines. These examples could be sourced from currently funded NSF projects.",
    paragraph2:
      "Schools of education faculty and students should interact with students and faculty from other departments on campus that use computer science to solve problems and explore hypotheses in their own disciplines. Suggestions for this include welcoming speakers to education classes or inviting faculty from other departments to speak in the school of education about their work.",
    paragraph3:
      "Faculty and students from schools of education should seek out case studies of interventions that did not produce learning gains in the multiple disciplines attempted in an interdisciplinary project and discuss what may have inhibited the learning.",
  },
  focusArea6: {
    name: "Focus Area 6",
    paragraph1:
      "Schools of education should ensure through programmatic requirements that K-8 teachers receive basic literacy instruction in computer science as well as foundational computer science education pedagogical instruction.",
    paragraph2:
      "Faculty in schools of education should have opportunities to familiarize themselves with relevant K-12 computer science education standards for integration into core pedagogical coursework.",
    paragraph3:
      "Candidates for ELL or special education licenses or certificates should include cases or examples with computer science instruction as a part of their training.",
  },
  focusArea7: {
    name: "Focus Area 7",
    paragraph1:
      "Computer science educational leaders should participate in conferences and gatherings of educational leadership communities (including superintendents, principals, etc.). Having computer science educators and researchers in these conferences will bring visibility to practicing educational leaders who are often mentors for new administrators.",
    paragraph2:
      "Faculty in educational leadership programs should use case studies with problems of practice for computer science education implementation. There are numerous examples such as Chicago Public Schools, the New York City public schools, and many smaller city or rural schools with computer science education implementation plans.",
  },
  focusArea8: {
    name: "Focus Area 8",
    paragraph1:
      "Schools of education and schools of computer science should work together to define opportunities for interdisciplinary coursework for interested computer science education PhD candidates.",
    paragraph2:
      "Current computer science education faculty should propose and seek funding for workshops for leaders of schools of education to promote understanding of quality computer science education publications and CVs for faculty candidates.",
    paragraph3:
      "If there are current faculty engaged in computer science education research, the school should seek to highlight opportunities for PhD candidates to work with these faculty. If not, schools of education should consider hiring candidates with computer science education research experience to produce a well-rounded faculty.",
  },
};
export default focusAreas;
