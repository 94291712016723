import React, { Component } from "react";
import "../styles/Footer.css";
import { Link } from "react-router-dom";

// images
import CSforALLLogo from "../images/logos/csforall-logo.svg";
import circuits from "../images/circuit-small.svg"
import microsoftLogo from "../images/logos/Microsoft-logo.svg";
import mongoDBLogo from "../images/logos/mongoDB-logo.svg";
import nsfLogo from "../images/logos/nsf-logo.png";
import uOfIndiana from '../images/logos/trident-large.png';
import google from '../images/logos/google-logo.png';
import { Button } from "reactstrap";

export class Footer extends Component {
  render() {
    return (
               <div>
                  <div className='w3-row'>
                     <div className="footer-nav w3-xlarge">
                        <div className='w3-col m10'>                      
                           <div>
                              <a href="https://www.csforall.org/" target="_blank" rel="noopener noreferrer"><img className="footer-logo" src={CSforALLLogo} alt="CSforALL Logo"/></a>
                           </div>
                              {/*  Footer Nav  */}
                              <div><Link to="/contact">CONTACT</Link></div>
                               {/*  Socials  */}
                           <div className="footer-social">
                              <a href="https://twitter.com/csforall?lang=en" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter footer-icon"></i></a>
                              <a href="https://www.facebook.com/CSforALLorg/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f footer-icon"></i></a>
                              <a href="https://www.instagram.com/explore/tags/csforall/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram footer-icon"></i></a>
                              <a href="https://medium.com/csforall-stories" target="_blank" rel="noopener noreferrer"><i className="fab fa-medium-m footer-icon"></i></a>
                           </div>
                        </div>
                        <div className='w3-col m2'>                      
                        <img className="circuit-small" src={circuits} alt="decorative circuit design" />
                        </div>
                     </div>
                  </div>
                  {/* row2 */}
                  <div className='w3-row'>
   
                     <div className='w3-col m5 w3-center w3-left-align'>
                     <p>Join the growing CSforALL Movement!</p>
                     <Button className="primary-button" target='_blank' href="https://www.csforall.org/become_a_member/requirements/">
                        Find out more
                     </Button>
                     <div className='w3-padding-16'></div>
                     </div>
                     <div className='w3-col m7 w3-center logo-block'>
                     <img className='logo' href='' src={google} alt="Google Logo" />
                     <img className='nsf' href='' src={nsfLogo} alt="NSF Logo" />
                     <img className='logo' href='' src={microsoftLogo} alt="Microsoft Logo" />
                     <br/>
                     <img className='logo' href='' src={mongoDBLogo} alt="MongoDB Logo" />
                     <img className='uofi' href='' src={uOfIndiana} alt="University of Indiana Logo" />
                     </div>
 
                   </div>


                  {/* row3 */}
               <div className="copyright w3-row">
                      <div className="copyright-text">© CSforALL 2019 | <a href="https://csforall.us16.list-manage.com/subscribe?u=e459b3c4b34620f61e2405c80&id=ff48bedf4b" rel="noopener noreferrer" target="_blank">CSforALL Newsletter</a> &nbsp;&nbsp; <a href="https://www.csforall.org/privacy/" className='pink' rel="noopener noreferrer" target="_blank">Privacy</a></div>
                </div>
 
               </div>
      );
  }
}

export default Footer;
